import clsx from "clsx";

const Logo = ({ color = "#000000", isAnimating = false }) => {
  const className = clsx("Logo", { "Logo--isAnimating": isAnimating });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 300 200"
      className={className}
    >
      <path
        d="M280.027 61.998c-11.93-36.006-43.435-47.939-59.854-26.211-16.751 22.168-12.58 72.395-19.939 76.097-5.12 2.576-12.801-19.716-24.656-93.745l-28.28-3.842-.011.078-.108-.015C134.485 109.049 130 135.733 128.932 135.653c-1.855-.139 13.448-80.464-5.064-105.918-9.738-13.389-28.28-10.9-39.191 5.801-17.757 27.179-14.015 87.019-16.71 87.661-2.498.596 2.586-52.78-12.119-68.863-9.538-10.432-27.149-5.187-36.273 14.078-13.064 27.586-.565 66.68.399 69.589 11.93 36.006 43.435 47.939 59.854 26.211 16.751-22.168 12.58-72.395 19.939-76.097 5.12-2.576 12.801 19.716 24.656 93.745l28.28 3.842.011-.078.108.015C165.515 90.951 170 64.267 171.068 64.347c1.855.139-13.448 80.464 5.064 105.918 9.738 13.389 28.279 10.9 39.191-5.801 17.757-27.179 14.015-87.019 16.71-87.661 2.498-.596-2.586 52.78 12.119 68.863 9.538 10.432 27.149 5.187 36.273-14.078 13.065-27.587.565-66.682-.398-69.59z"
        fill={color}
      />
    </svg>
  );
};

export default Logo;
