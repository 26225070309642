const Message = ({ message = null }) => {
  if (!message) return null;

  return (
    <div className="Message">
      <div className="Message__content">{message}</div>
    </div>
  );
};

export default Message;
