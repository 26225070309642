import Video from "components/Video";
import ReactPlayer from "react-player";

const VideoBlock = ({ brightcoveVideoId = null, embed = null }) => {
  if (brightcoveVideoId) {
    return (
      <div className="VideoBlock">
        <div className="VideoBlock__video">
          <Video brightcoveVideoId={brightcoveVideoId} />
        </div>
      </div>
    );
  }

  if (embed) {
    return (
      <div className="VideoBlock">
        <div className="VideoBlock__video">
          <ReactPlayer url={embed} />
        </div>
      </div>
    );
  }
};

export default VideoBlock;
