/* eslint-disable react/prop-types */
import * as Icons from "components/Icons";

const Action = ({ label = "Action", icon = "ArrowRight" }) => {
  const Icon = Icons[icon];

  return (
    <div className="Action">
      <div className="Action__label">{label}</div>
      <div className="Action__icon">
        <Icon />
      </div>
    </div>
  );
};

export default Action;
