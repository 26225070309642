import clsx from "clsx";
import dayjs from "dayjs";

const EntryMeta = ({ entry, theme = "light", textColor = null }) => {
  const formattedDate = dayjs(entry.postDate).format("DD MMMM YYYY");

  const className = clsx("EntryMeta", `EntryMeta--${theme}`, "space-x-4");

  return (
    <div className={className} style={{ color: textColor }}>
      {entry.readTime && (
        <div className="EntryMeta__readTime">
          <div className="EntryMeta_label">Read: {entry.readTime}</div>
        </div>
      )}
      {entry.byline && (
        <div className="EntryMeta__byline">
          <span dangerouslySetInnerHTML={{ __html: entry.byline }} />
        </div>
      )}
      {entry.postDate && <div className="EntryMeta__date">{formattedDate}</div>}
    </div>
  );
};

export default EntryMeta;
